html {
    scroll-behavior: smooth;
}

.root *{
    padding: 10px;
}

body{
    margin: 0;
    background: #000000;
}

.App {
    text-align: center;
}

/* ------ Cookie bar ------ */
.consent-bar {
    position: fixed;
    bottom: 0;
    max-width: 78%;
    /* min-height: 112px; */
    background-color: #000;
    color: #fff;
    padding: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: "Oxanium", sans-serif;
    bottom: 5%;
    left: 5%;
    /* box-shadow: 0px 5px 11px 0px #000000; */
    border: 2px solid #4A020A;
    flex-direction: column;
}

.consent-text {
    text-align: center;
}

.consent-button {
    background-image: url('./assets/herosection/Button.png');
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
    margin: 12px;
    font-size: 18px;
    font-family: "Oxanium", sans-serif;
    width: 180px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 24px;
}

.consent-button:hover {
    background-image: url('./assets/herosection/Button2.png');
}

.privacy-link {
    color: #F82B4E;
}

.consent-button-secondary {
    background-color: #000000;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    /* border-radius: 5px; */
    margin-left: 10px;
    margin: 12px;
    font-size: 12px;
    font-family: "Oxanium", sans-serif;
}

@media (min-width: 768px) {
    .consent-bar {
        position: fixed;
        bottom: 0;
        width: 768px;
        min-height: 100px;
        background-color: #000;
        color: #fff;
        padding: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        font-family: "Oxanium", sans-serif;
        bottom: 5%;
        left: 5%;
        /* box-shadow: 0px 5px 11px 0px #000000; */
        border: 2px solid #4A020A;
        flex-direction: row;
        margin-top: 0px;
    }

    .consent-text {
        text-align: left;
    }
}
/* ------ Cookie bar ------ */

/* Not found page */
.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    font-family: "Oxanium", sans-serif;
    background: #000;
    color: #fff;
    box-shadow: 0 0px 15px 5px #000000;
}

.home-btn {
    background-image: url('./assets/herosection/Button.png');
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px 30px;
    height: 80%;
    margin-top: 62px;
}

.home-btn:hover {
    background-image: url('./assets/herosection/Button2.png');
}

section {
    /* padding-top: 5%; */
    min-height: 100vh;
    font-family: "Oxanium", sans-serif;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: auto;
    color: #ffffff;
    padding: 0% 5%;
    box-shadow: 0px 0px 15px 5px #000000;
}

.Main_title {
    display: none;
}

/* Added more style --MATEO */
.ContactDiv div {
    margin-bottom: 24px;
}

.ContactDiv button {
    padding: 24px 24px !important; 
}

/* --snap scroll CSS
.snap-container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.snap-section {
    scroll-snap-align: start;
    height: 100vh;
}
*/

.dev-container {
    margin-top: 64px;
}

.policies {
    text-align: left;
    padding: 5%;
    max-width: 100%;
    margin-top: 62px;
}

.policies h2 {
    font-size: 36px;
}

h2 {
    display: block;
    font-size: 48px;
    font-weight: 900;
    text-transform: uppercase;
    padding-top: 60px;
    margin: 0;
    color: #4A020A;
}

section.PrivacyPolicy, section.TermsConditions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    font-family: "Oxanium", sans-serif;
    background: #000;
    color: #fff;
    box-shadow: 0 0px 15px 5px #000000;
}

/* Investor page styles --Mateo */
.invs-img {
    width:100%;
}

.roadmap-list {
    list-style-type: none; 
    padding-left: 0; 
}

.roadmap-list li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 20px;
}

.roadmap-list li:before {
    content: '\2022';
    position: absolute;
    left: 0;
    color: white; 
}

.roadmap-list li.checked:before {
    content: '\2713'; 
    color: white; 
}

.roadmap-list li.unchecked:before {
    content: '\2610'; 
    color: white; 
}

.default_page {
    text-align: left;
    padding: 5%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
}

.investor_content {
    justify-content: start;
}

.investor_content h3 {
    padding: 12px 0px;
    color: #4A020A;
    font-size: 24px;
    margin-top: 28px;
    text-align: left;
}

.investor_content p {
    text-align: left;
}

.InvestorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    font-family: "Oxanium", sans-serif;
    background: #000;
    color: #fff;
    box-shadow: 0 0px 15px 5px #000000;
}

.InvestorPage h2 {
    font-size: 32px;
    margin-bottom: 18px;
    margin-top: 24px;
    text-align: left;
}

.token_text {
    text-decoration: underline;
}

.investor_btn {
    background-image: url('./assets/herosection/Button.png');
    cursor: pointer;
    text-transform: uppercase;
    margin: 24px 12px;
    padding: 12px 24px;
    margin: 10px;
    width: 294px;
    height: 80px;
}

.investor_btn:hover {
    background-image: url('./assets/herosection/Button2.png');
}

.investor_cta {
    text-align: center;
    margin-top: 48px;
}

@media (min-width: 768px) {
    .investor_btn{
        margin: 10px;
        width: 294px;
        height: 80px;
    }

    .home-btn {
        margin: 32px;
        width: 294px;
        height: 80px;
    }

    .policies {
        max-width: 40%;
    }

    .InvestorPage h2 {
        font-size: 64px;
    }

    .default_page {
        max-width: 50%;
    }
}
/* --MATEO */


/*Text*/
h3, h4 {
    margin: 0;
    padding: 12px;
}

p{
  margin: 0;
}

/* links */
/* Default link style */
a {
    color: #FF005C; 
    text-decoration: underline; 
}

    /* Link hover state */
    a:hover {
        color: #FF3377; 
        text-decoration: underline; 
    }

    /* Link active state */
    a:active {
        color: #FF6699; 
        text-decoration: underline;
    }

    /* Link visited state */
    a:visited {
        color: #CC0044; 
        text-decoration: none; 
    }

/* Nav */

nav {
  display:flex;
  flex-direction: row;
  position: fixed;
  top:0;
}


.Menu { 
  display: flex;
  justify-content: center;
  align-items: center;
}

.Menu a {
  color:#F82B4E;
  font-size: 24px;
  font-family: "Oxanium", sans-serif;
  padding: 12px;
  margin-left: 12px;
  text-decoration:none;
  font-weight: 500;
}

.App-logo {
 
  pointer-events: none;
  padding: 24px;  
}

@media (max-width: 700px){

  nav{
    justify-content: space-between;
    width: 94%;
  }
  .HamburgerMenu{
    display:flex;
    align-items: center;
  }
  .Menu{
    display:none
  }
}

@media (min-width: 768px){
  .HamburgerMenu{
    display:none;
  }
  .Menu{
    display:flex;
  }
  section {
      padding: 0%;
  }
  h2 {
      font-size: 96px;
  }

  h4 {
      font-size: 24px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
    
  }
}

.App-header {
  /* background-color: #120101; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #ff2727;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
    border: 0;
    box-shadow: none;
    background: transparent;
    color: #ffffff;
    font-size: 24px;
    font-family: "Oxanium", sans-serif;
}

/* Remove watch trailer btn 
button.btn1 {
    background-image: url('./assets/herosection/Button.png');
    cursor: pointer;
}
*/

button.btn2 {
    background-image: url('./assets/herosection/Button.png');
    cursor: pointer;
    text-transform: uppercase;
}

button.btn2:hover {
    background-image: url('./assets/herosection/Button2.png');
}


.HeroButtonsDiv a:hover {
    text-decoration: none;
}

/* - Mateo */
