
/* Footer */
.footer {
    background-image: url('../../assets/theproject/TheProjectBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    padding: 10% 0 100px 0;
    color: white;
    font-family: "Oxanium", sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Add overlay for gradient */
.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgb(0 0 0 / 65%) 0%, rgb(0 0 0 / 16%) 100%);
    z-index: 1;
}

.footer > * {
    position: relative;
    z-index: 2;
}
/* --Add overlay for gradient */

.footer h4 {
    margin-bottom: 20px;
    font-size: 1.5rem; 
    color: #F82B4E; 
    letter-spacing: 2px;
}

.email-signup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-signup form {
    display: flex;
    align-items:center;
    justify-content:center;
    width: 90%;
}

.email-signup input[type='email'] {
    padding: 10px;
    border: none;   
    width: 75%;
    max-width: 80%;
    font-size: 1rem; 
}

.email-signup button {
    padding: 10px 20px;
    border: none;
    background-color: #F82B4E;
    color: black;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1rem;
}

.newsletter-good-resp {
    width: 100%;
}

.social-media {
    margin-top: 96px;
    padding-bottom: 72px;
}

.social-media h4 {
    font-size: 1.5rem;
    color: #F82B4E;
}

.SMiconDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
}

.SocialMediaIcon {
    margin: 0 10px;
}

.SocialMediaIcon img {
    width: 58px;
    height: 52px;
    padding: 12px;
}

.SocialMediaIcon img:hover {
    filter: brightness(0.8);
}

.privacy-container {
    display: flex;
    margin-top: 12px;
    justify-content: center;
}

.policy_link {
    color: #F82B4E !important;
    font-size: 12px;
    margin-right: 8px;
    margin-left: 0;
}

.footer-bottom {
    position: absolute;
    bottom: 60px;
}

.copyright, .developed-by {
    margin: 10px 0;
    color: #F82B4E;
}

/* Tablet + screen size */
@media (min-width: 768px) {

    .newsletter-good-resp {
        width: 50% !important;
    }

    .privacy-container {
        justify-content: flex-start;
    }

    .email-signup form {
        width: 100%;
    }

    .SMiconDiv {      
        flex-direction: row;
    }

    .footer-bottom {    
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px; 
        font-size: 0.875rem;
        color: white;
    }

    .copyright, .developed-by {
        margin: 0;
    }
}


