
/* Alpha Register Form */
.AlphaRegister {
    background-image: url('../assets/AlphaReg/AlphaBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-top: 15%;
}

.AlphaRegister h4, h5 {
    color: #F82B4E;
    margin: 0;
}

.AlphaRegister h2 {
    font-size: 48px;
}

.AlphaRegister h4 {
    font-size: 24px;
    text-transform: uppercase;
}

.AlphaRegister h5 {
    font-size: 16px;
    text-transform: uppercase;
}

.GetAlphaForm {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.AlphaRegForm {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding-bottom: 10%;
}

.AlphaRegForm .ARdiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    width: 50%;
}

.ARdiv.half {
    padding: 0;
}

.doubleAR {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

/* Radio button selectors */
.AlphaRegForm div div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.AlphaRegForm div div div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AlphaRegForm div div div input {
    margin: 0px;
    padding: 0px;
}

.AlphaRegForm div div div label {
    margin: 0px;
    padding-left: 12px;
}

/* Radio button selectors */

.AlphaRegForm input, textarea {
    margin-bottom: 24px;
    background-color: rgb(4 4 4 / 61%);
    border: 1px solid #F82B4E;
    border-radius: 10px;
    color: #8b8b8b;
    font-size: 16px;
    padding: 8px 24px;
    height: 34px;
}

.AlphaRegForm textarea {
    height: 92px;
}

.AlphaRegForm select {
    margin-bottom: 24px;
    background-color: rgb(4 4 4 / 61%);
    border: 1px solid #F82B4E;
    border-radius: 10px;
    color: #8b8b8b;
    font-size: 16px;
    padding: 16px;
}

select#FindUSAR option {
    background-color: #000;
    padding: 12px;
    color: antiquewhite;
    border-radius: 12px 0 12px 0;
}

.AlphaRegForm label {
    text-align: start;
    font-size: 16px;
    margin-bottom: 8px;
}

.ARdiv p {
    text-align: left;
}

.radio-container {
    flex-wrap: wrap;
}

.form_required {
    color: red;
    font-size: 12px;
    font-style: italic;
}

.AlphaRegForm button {
    background-image: url('../assets/herosection/Button.png');
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 1% 3%;
}

.alpha-submit {
    background-image: url('../assets/herosection/Button.png');
    margin: 10px;
    width: 220px;
    height: 60px;
}

.alpha-submit:hover {
    background-image: url('../assets/herosection/Button2.png');
}

.namesurnameAR div {
    flex-direction: column;
}

@media (max-width:768px) {

    .AlphaRegForm .ARdiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 90%;
    }

    .ARdiv.half {
        padding: 0;
    }

    .doubleAR {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 1000;
}

.modal-content {
    background-color: #1e1e1e;
    padding: 40px 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    width: 90%;
    color: #ffffff;
}

.modal-content > img {
    max-width: 200px;
    margin-bottom: 20px;
}

.modal-content > h3 {
    color: #F82B4E;
    margin-bottom: 20px;
}

.modal-content > p {
    margin-bottom: 20px;
    line-height: 1.5;
}

.modal-content > .note {
    color: #F82B4E;
    font-size: 0.9em;
    margin-bottom: 20px;
}

.modal-content > h4 {
    color: #F82B4E;
    margin-bottom: 20px;
}

.modal-content > button {
    background-image: url('../assets/herosection/Button.png');
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 1% 3%;
    transition: transform 0.8s ease-in-out;
}

.modal-content button:hover {
    background-image: url('../assets/herosection/Button2.png');
}

@media (min-width:768px) {

    .AlphaRegister h2 {
        font-size: 96px;
    }

    .AlphaRegister {
        padding-top: 5%;
    }

    .AlphaRegForm {
        width: 80%;
    }

    .alpha-submit {
        margin: 10px;
        width: 294px;
        height: 80px;
    }
}