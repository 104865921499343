.NavModule {
    position: fixed;
    top: 0;
    right: -100%;
    width: 300px;
    height: 100%;
    transition: right 1.2s ease;
    z-index: 1001;
    background: rgb(0,0,0);
    background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgb(25 0 3) 100%);
}

.exitBtnMenuDiv{
    display:flex;
    width:100%;
    padding-bottom:25%;
}

.NavModuleShow {
    right: 0; 
}

.NavModuleHide {
    right: -100%; 
}


/* NavModule */
@media (min-width:768px) {
    .Nav.Module {
        display: none;
    }
}

@media (max-width:768px) {
    .Nav.Module {
        display: flex;
        z-index: 1000;     
        position: fixed;
        top: 0px;
        height: 100vh;
        width: 100vw;
        flex-direction: column;
        padding-top: 30%;
    }


    .Menu.Mobile {
        display: flex;
        flex-direction: column;
    }

    .Menu.Mobile a {
        font-size: 32px;
    }
}
