
/* Hero section */
.Hero {
    background-image: url('../../assets/herosection/Strike_Protocol_Hero.png');
    background-position: right;
}

/* Add overlay for gradient */
.Hero::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 115%;
    background: linear-gradient(to top, rgb(0 0 0) 0%, rgb(0 0 0 / 05%) 100%);
    z-index: 1;
}

.Hero > * {
    position: relative;
    z-index: 2;
}
/* --Add overlay for gradient */

.title {
    width: 100%;
    padding-top:25%; /* Was 60% */
    max-width:700px;
}

.HeroButtonsDiv {
    /*  padding-top: 45%; */

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.HeroButtonsDiv a{
    text-decoration:none;
}

.target-btn {
    width: 24px;
}

.btn1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}

.cta-hero {
    width: 64px;
    height: 68px;
}

.cta-btn:hover {
    cursor: pointer;
}

.cta-btn{
    margin-bottom: 48px !important;
}

.announcement-text{
    display: none;
}

.announcement-container {
    width: 80%;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.announcement-text-mobile {
    text-align: center;
}

.cta-container {
    padding-top: 4%;
}


/* Tablet + screen size */
@media (min-width:700px) {
    .title {
        padding-top: 10%;
    }

    .HeroButtonsDiv {
        padding-top: 2%; /* Was 5% */
        flex-direction:column; /* Before was row */
    }

    .announcement-container {
        width: 80%;
    }

    .announcement-text {
        display: block;
    }

    .announcement-text-mobile {
        display: none;
    }

}

.HeroButtonsDiv button {
    margin: 10px;
    width: 294px;
    height: 80px;
}


