
/* FAQ */
.FAQ {
    /*background-image: url('../../assets/FAQ/FAQbg.png'); -- Mateo */
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    /* new new height - delete when faq back !! */
    min-height: 45vh;
}

/* Add overlay for gradient */
    /* 
.FAQ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgb(0 0 0) 0%, rgb(0 0 0 / 45%) 100%);
    z-index: 0;
}

.FAQ>* {
    position: relative;
    z-index: 2;
}
    */
/* --Add overlay for gradient */

.FAQ .faq-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
}

.constructordivFAQ {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    /* resize --smaller */
    width: 90%;
}

.FAQQuestion, .FAQAnswer {
    background-image: url('../../assets/FAQ/FAQquestionBG.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 10px;
    width: 100%;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.FAQQuestion p,
.FAQAnswer p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.toggleButtonFAQ {
    display: none;
}

.video-container {
    margin-top: 64px;
    margin-bottom: 64px;
    width: 100%;
}

.video-container video {
    width: 100%;
}


@media (max-width:768px) {

    .FAQ>div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .toggleButtonFAQ {
        display: inline-block;
    }
}

/* Tablet + screen size */
@media (min-width:768px) {

    .FAQQuestion {
        width: 100%;
        min-width: 380px;
        min-height: 42px;
        max-width: 60%;
    }

    .video-container video {
        width: 50%;
    }

    .FAQAnswer {
        max-width: 50%;
        width: 100%;
        min-width: 380px;
        min-height: 42px;
        max-width: 60%;
    }

    .FAQQuestion, .FAQAnswer {
        width: 60%;
    }

    .constructordivFAQ {
        width: 42%;
    }
}


.FAQQuestion:hover,
.FAQAnswer:hover {
    transform: scale(1.05);
}

.FAQAnswer {
    background-image: url('../../assets/FAQ/FAQanswerBG.png');
}

.qInA {
    font-weight: 900;
}

/* Animation for pop-out effect */
@keyframes pop-out {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.pop-out {
    animation: pop-out 0.6s ease-in-out;
}