/* Partners */

.Partners {
    background: #000000;
}

.second-Partners{
    padding:40px 0;
    
}

.Partner-div {
    padding: 12px;
}


@media (min-width:768px) {
    .Partners {
        min-height: auto;
    }

    .second-Partners {
        display: flex;
        padding: 100px 330px;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 20px 27px;
        flex-shrink: 0;
        flex-wrap: wrap;
        max-width: 900px;
        margin: auto; /* Mateo */
    }

    .Partner-div {
        padding: 0px;
    }
}