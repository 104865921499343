
/* The Project */
.TheProject {
    background-image: url('../../assets/theproject/TheProjectBG.png');   
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Add overlay for gradient */
.TheProject::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 55%, rgb(0 0 0 / 0%) 88%);
    z-index: 1;
}

.TheProject > * {
    position: relative;
    z-index: 2;
}
/* --Add overlay for gradient */

.TheProject article h4 {
    color: #F82B4E;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
}

.TheProject p {
    padding: 12px;
    padding-top: 0;
    color: #ffffff;
}

.TheProjectImg {
    width: 100%;
}

/* Tablet + screen size */
@media (min-width: 768px) {

    .TheProjectText h4 {
        text-align: left;
    }

    .TheProject h2 {
        padding-top: 5%;
    }

    .TheProject div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 70%;
        width: 60%;
        align-items: center;
    }

    .TheProject article h4 {
        font-size: 32px;
    }

    .TheProjectText p {
        text-align: start;
        font-size: 16px;
        padding-right: 5%;
    }
}

@media (min-width: 1000px) {

    .TheProjectText {
        width: 90%;
    }

}




