
/* Playstyles */
.playStyles {
    position: relative;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* Add overlay for gradient */
.playStyles::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgb(0 0 0 / 30%) 50%), linear-gradient(to bottom, rgb(83 2 2 / 39%) 50%, rgb(0 0 0) 100%);
    z-index: 0;
}

.playStyles > * {
    position: relative;
    z-index: 2;
}
/* --Add overlay for gradient */

.mainPlayStyles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainPlayStyles article {
    background-image: url('../../assets/Rewards/RwrdSpecFrame.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 36px 20px 20px 20px;
    width: 80%;
}

.mainPlayStyles article p {
    padding: 20px;
}

.PSArrows {
    z-index: 10;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 42%;
    width: 100%;
    justify-content: space-between;
}

.playStyles h4 {
    color: #F82B4E;
    text-align: center;
    font-family: "Oxanium", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

/* Breadcrumb slider active show */
.playStylesBreadCrumb {
    display: flex;
    justify-content: center;
    margin-top: 72px;
}

.breadcrumb {
    width: 62px;
    height: 12px;
    background-color: #4A020A;
    color: white;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.breadcrumb.active {
    background-color: #F82B4E;
}
/* --Breadcrumb slider active show */

.PSskills h5 {
    font-size: 24px;
}

.playStyles h2 {
    color: #4A020A;
}

/* -- Mateo - temp override */
.playStyles h2.clicked {
    color: #4A020A;
}

/* -- Mateo - temp override #550092 */
.playStyles h4.clicked {
    color: #F82B4E;
}

/* Tablet + screen size */
@media (min-width: 768px) {
    .mainPlayStyles {
        flex-direction: row;
        position: relative;
    }

    #Commander {
        margin-left: 5%;
    }

    .mainPlayStyles article {
        color: #ffffff;
        flex: 1;
        max-width: 300px;
        position: absolute;
    }

    .PSoutfits {
        z-index: 10;
        left: 18%;
        top: 32%;
        transform: translateY(-50%);
    }

    .PSskillsDescription {
        z-index: 10;
        right: 19%;
        top: 82%;
        transform: translateY(-50%);
    }

    .playStyles h4 {
        font-size: 48px;
    }

    .PSoutfits#Infiltrator {
        left: 60%;
    }

    .PSskillsDescription#Infiltrator {
        right: 60%;
    }

}

.playerImgFrame {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    z-index: 1;
}

.playerImgBG {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
}

.playerImg {
    width: 100%;
    z-index: 2;
    height: 278px;
}

.PSArrows button img {
    width: 42px;
    height: 42px;
}

/* Tablet + screen size */
@media (min-width: 768px) {
    .playerImg {
        width: 33%;
        height: 100%;
    }

    .mainPlayStyles {
        justify-content: center;
    }

    .PSArrows {
        width: 80%;
        left: 10%;
        top: 52%;
        transform: translateY(-50%);
    }

    .PSArrows button {
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        width: 70px;
        height: 70px;
    }

    .PSArrows button img {
        width: 70px;
        height: 70px;
    }

    .footer-bottom {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
        font-size: 0.875rem;
        color: white;
    }

    .copyright, .developed-by {
        margin: 0;
        white-space: nowrap;
    }
}
