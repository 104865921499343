
/* Contact form */
.Contact {
    /* background-image: url('../../assets/FAQ/FAQbg.png'); */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
}

.Contact h2 {
    padding-top: 10%;
}

.GetContactForm {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.ContactContainer {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    padding-bottom: 10%;
    align-content: center;
}

.Contact h4 {
    color: #F82B4E;
    font-size: 16px;
    text-transform: uppercase;
    padding-bottom: 32px;
}

.ContactContainer div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.ContactContainer div input, .ContactContainer div textarea {
    width: 100%;
}

.ContactContainer div label {
    text-align: center;
    margin-bottom: 6px;
}

.ContactContainer button {
    margin: 10px;
    width: 220px;
    height: 60px;
}

.ContactContainer button:hover {
    background-image: url('../../assets/herosection/Button2.png');
}

.contact-good-resp div {
    justify-content: center !important;
}

/* Tablet + screen size */
@media (min-width:768px) {
    .ContactContainer button {
        margin: 10px;
        width: 294px;
        height: 80px;
    }

    .ContactContainer div input, .ContactContainer div textarea {
        width: 50%;
    }

    .Contact h4 {
        color: #F82B4E;
        font-size: 24px;
        padding: 12px 0px 36px 0px;
    }
}
