.nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: background-color 0.3s ease, height 0.3s ease, padding 0.3s ease;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.nav.scrolled {
    background-color: rgba(0, 0, 0, 0.97); 
    color: white; 
    height: 70px; 
    padding: 0 0px; 
}

.Logo-Nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
}

.cta-hero-nav {
    width: 36px;
    margin: 0px 0px 0px 34px;
}
.cta-btn-nav{
    margin: 0px;
}

.cta-btn-nav:hover {
    cursor: pointer;
}

/* Mateo 
.Logo-Nav:hover {
filter: contrast(15.5);
}
*/

.Logo-Nav img {
    height: 60px; /* Fixed height for the logo */
    transition: transform 0.3s ease; /* Smooth transition for logo height */
}

.Menu {
    display: flex;
    justify-content: space-between;
}

/* Mateo */
.Menu a:hover {
    text-shadow: 0px 0px 5px #F82B4E;
}

.HamburgerMenu{
    margin:0 30px;
}

.HamburgerMenu img {
    height: 60px; /* Fixed height for the hamburger menu icon */
    transition: height 0.3s ease; /* Smooth transition for icon height */
}

.desktopNavContainer {
    display: flex;
    flex-direction: row;
}

nav .contactDiv {
    display: flex;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

@media (min-width: 700px) {
    nav .contactDiv {
        display: flex;
    }
}

.ContactBtn {
    background-image: url('../../assets/herosection/Button.png');
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px 30px;
    height: 80%;
}

.ContactBtn:hover {
    background-image: url('../../assets/herosection/Button2.png');
}

    .nav::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 3px;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5MjMiIGhlaWdodD0iMyIgdmlld0JveD0iMCAwIDkyMyAzIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTIgMS41SDkyMS4wNDQiIHN0cm9rZT0idXJsKCNwYWludDBfbGluZWFyXzVfNykiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfNV83IiB4MT0iMiIgeTE9IjMiIHgyPSI5MjEuMDQ0IiB5Mj0iMyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRjgyQjRFIiBzdG9wLW9wYWNpdHk9IjAiLz4KPHN0b3Agb2Zmc2V0PSIwLjQiIHN0b3AtY29sb3I9IiNGODJCNEUiLz4KPHN0b3Agb2Zmc2V0PSIwLjQ5NSIgc3RvcC1jb2xvcj0iI0Y4MkI0RSIvPgo8c3RvcCBvZmZzZXQ9IjAuNiIgc3RvcC1jb2xvcj0iI0Y4MkI0RSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGODJCNEUiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=') no-repeat center;
        background-size: 100% 100%;
        animation: stretch-out 2s ease-in-out forwards;
    }

.nav.scrolled::after {
    animation: stretch-in 2s ease-in-out forwards;
}

@keyframes stretch-out {
    from {
        width: 0;
        left: 50%;
    }

    to {
        width: 100%;
        left: 0;
    }
}

@keyframes stretch-in {
    from {
        width: 100%;
        left: 0;
    }

    to {
        width: 0;
        left: 50%;
    }
}
